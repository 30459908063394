import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './View/home/home.component';
import { FahrzeugeComponent } from './View/fahrzeuge/fahrzeuge.component';
import { KontaktComponent } from './View/kontakt/kontakt.component';
import { KontaktformularComponent } from './View/kontaktformular/kontaktformular.component';
import { ReiseangebotComponent } from './View/reiseangebot/reiseangebot.component';
import { TeamComponent } from './View/team/team.component';
import { ReiselistePipe } from './Pipe/reiseliste.pipe';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AktuellComponent } from './View/aktuell/aktuell.component';
import { BerichtComponent } from './View/berichte/bericht.component';


registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FahrzeugeComponent,
    KontaktComponent,
    KontaktformularComponent,
    ReiseangebotComponent,
    TeamComponent,
    ReiselistePipe,
    AktuellComponent,
    BerichtComponent  ],
  imports: [
    BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: "de" }],
  bootstrap: [AppComponent]
})
export class AppModule {

   
}


