import { Component, OnInit } from '@angular/core';
import { Reisen } from '../../Model/reisen';
import { getFormattedDate } from '../../View/global';
import { ReiseService } from '../../Service/reise.service';



@Component({
  selector: 'app-reiseangebot',
  templateUrl: './reiseangebot.component.html',
  styleUrls: ['./reiseangebot.component.css'],
  providers: [ReiseService]
})
export class ReiseangebotComponent implements OnInit {



    reiseList: Reisen[];
    currentReise: Reisen;
    hiddenDescription: boolean;
    CurrentIndex: number;
    //buttonNames: string[];
    date: Date;
    dateStr: string;




    constructor(private reiseservice: ReiseService) { }

    ngOnInit() {
        this.getReisen();
        this.hiddenDescription = false;
        this.date = new Date();
        this.date.getDate();
        this.dateStr = getFormattedDate(this.date);
        console.log(getFormattedDate(this.date));
    }


    getReisen(): void {
        console.log("home reise");
        this.reiseservice.getReisen().subscribe(
            data => {
                this.reiseList = data;
            },
            err => {
                console.log(' Error in reiseList elements');
            },
            () => {
                console.log('Finish');
                if (this.reiseList != null) {
                    for (let i = 0; i < this.reiseList.length; i++) {
                        this.reiseList[i].ButtonName = "Mehr Infos";
                        console.log(this.reiseList[i]);
                    }
                    this.sortReiseliste();
                }
            }
        );

    }

    sortReiseliste(): void {
        this.reiseList.sort((obj1, obj2) => {
            if (obj1.Reisedatum > obj2.Reisedatum) {
                return 1;
            }

            if (obj1.Reisedatum < obj2.Reisedatum) {
                return -1;
            }
            return 0;
        });
    }

    //get title() {
    //    return this.hiddenDescription ? 'Weniger Infos' : 'Mehr Infos';
    //}

    get title() {
        return this.CurrentIndex > -1 ? this.reiseList[this.CurrentIndex].ButtonName : 'Mehr Infos';
    }


    SetcurrentReise(reisen: Reisen, i: number) {
        document.body.style.cursor = 'wait';
        this.currentReise = reisen;
        this.hiddenDescription = !this.hiddenDescription
        this.CurrentIndex = i;
        document.body.style.cursor = 'default';
    }



}

