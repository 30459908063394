import { Component, OnInit } from '@angular/core';
import { Bericht } from '../../Model/bericht';
import { ReiseberichtService } from '../../Service/reisebericht.service';


@Component({
  selector: 'app-bericht',
  templateUrl: './bericht.component.html',
  styleUrls: ['./bericht.component.css']
})
export class BerichtComponent implements OnInit {

    berichtList: Bericht[];
    currentBericht: Bericht;
    hiddenDescription: boolean;
    CurrentIndex: number;



    constructor(private reiseberichtService: ReiseberichtService) { }

    ngOnInit() {
        this.getBerichte();
        this.hiddenDescription = false;
    }


    getBerichte(): void {
        console.log("home reisebericht");
        this.reiseberichtService.getReisenberichte().subscribe(
            data => {
                this.berichtList = data;
            },
            err => {
                console.log(' Error in reiseList elements');
            },
            () => {
                console.log('Finish');
            }
        );

    }




    SetcurrentBericht(bericht: Bericht, i: number) {
        document.body.style.cursor = 'wait';
        this.currentBericht = bericht;
        this.hiddenDescription = !this.hiddenDescription
        this.CurrentIndex = i;
        document.body.style.cursor = 'default';
    }



}

