import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KontaktComponent }   from './View/kontakt/kontakt.component';
import { TeamComponent }   from './View/team/team.component';
import { FahrzeugeComponent }     from './View/fahrzeuge/fahrzeuge.component';
import { ReiseangebotComponent }     from './View/reiseangebot/reiseangebot.component';
import { HomeComponent } from './View/home/home.component';
import { KontaktformularComponent } from './View/kontaktformular/kontaktformular.component';
import { BerichtComponent} from './View/berichte/bericht.component';
import { AktuellComponent} from './View/aktuell/aktuell.component';


const routes: Routes = [
    { path: 'Home', component: HomeComponent },
    { path: 'Aktuell', component: HomeComponent },
    { path: 'Angebot', component: ReiseangebotComponent },
    { path: 'Team', component: TeamComponent },
    { path: 'Berichte', component: BerichtComponent },
    { path: 'Fahrzeuge', component: FahrzeugeComponent },
    { path: 'Aktuelles', component: AktuellComponent },
    { path: 'Kontakt', component: KontaktformularComponent },
    { path: '**', component: HomeComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

