import { Component, OnInit } from '@angular/core';
import { Reisen } from '../../Model/reisen';
import { getFormattedDate } from '../../View/global';
import { ReiseService } from '../../Service/reise.service';
import { ViewChild, ElementRef } from "@angular/core";


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [ReiseService]
})
export class HomeComponent implements OnInit {

    reiseList: Reisen[] = [];
    reiseListtemp: Reisen[] = [];
    date: Date;
    videoWidth: number;

    SliderPicture1: string = '../assets/slider/sprintsuisse_1.jpg';
    SliderPicture2: string = '../assets/slider/sprintsuisse_2.jpg';
    SliderPicture3: string = '../assets/slider/sprintsuisse_3.jpg';
    SliderPicture4: string = '../assets/slider/sprintsuisse_4.jpg';

    videoUrl: string = '../assets/video/sprintsuisse.mp4';

    PanelheightHeader: number = 230;
    PanelheightBody: number = 400;

    name = "Angular";
    //@ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
    @ViewChild("videoPlayer") videoplayer: ElementRef;
    isPlay: boolean = false;


    constructor(private reiseservice: ReiseService) { }

    ngOnInit() {
        this.getReisen();
        this.date = new Date();
        this.date.getDate();
        this.play();
        this.videoWidth = document.getElementById("vifra").offsetWidth;
        console.log("videoWidth");
        console.log(this.videoWidth);
        console.log("videoWidth end");

    }

    sortReiseliste(): void {
        this.reiseList.sort((obj1, obj2) => {
            if (obj1.Reisedatum > obj2.Reisedatum) {
                return 1;
            }

            if (obj1.Reisedatum < obj2.Reisedatum) {
                return -1;
            }
            return 0;
        });
    }


    getReisen(): void {
        console.log("home reise");
        this.reiseservice.getReisen().subscribe(
            data => {
                this.reiseList = data;
            },
            err => {
                console.log(' Error in reiseList elements');
            },
            () => {
                console.log('Finish');
                if (this.reiseList != null) {
                    for (let i = 0; i < this.reiseList.length; i++) {
                        this.reiseList[i].ButtonName = "Mehr Infos";
                        console.log(this.reiseList[i]);
                    }
                    this.sortReiseliste();
                }
            }
        );

    }



    ChangeHeightOnViewPanel() {
        console.log('Execute ChangeHeightOnViewPanel');
        var element = document.getElementById('MasterHeight');
        if (element.offsetHeight) {
            this.PanelheightBody = Number(element.offsetHeight);
            this.PanelheightBody = this.PanelheightBody - this.PanelheightHeader;
        }
        else {
            this.PanelheightBody = 250;
        }
        console.log('PanelHeigt is ' + this.PanelheightBody);
    }

    play () {
        var myVideo: any = document.getElementById("my_video_1");
        console.log("Start video play");
        myVideo.play();
        this.isPlay = true;
    }


    toggleVideo(event: any) {
        this.videoplayer.nativeElement.play();
    }

    playPause() {
        var myVideo: any = document.getElementById("my_video_1");
        if (myVideo.paused) myVideo.play();
        else myVideo.pause();
    }

    makeBig() {
        var myVideo: any = document.getElementById("my_video_1");
        //myVideo.width = 560;
    }

    makeSmall() {
        var myVideo: any = document.getElementById("my_video_1");
        //myVideo.width = 320;
    }

    makeNormal() {
        var myVideo: any = document.getElementById("my_video_1");
        //myVideo.width = 420;
    }

    skip(value) {
        let video: any = document.getElementById("my_video_1");
        video.currentTime += value;
    }

    restart() {
        let video: any = document.getElementById("my_video_1");
        video.currentTime = 0;
    }
}





