import { Injectable } from '@angular/core';
import { Aktuell } from '../Model/aktuell';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AktuellService {

    filename: string;


    constructor(private _http: HttpClient) {
        this.filename = '../Data/Aktuell.json';
    }


    getAktuell(): Observable<Aktuell[]> {
        return this._http.get<Aktuell[]>(this.filename).pipe(
            catchError(this.handleError('getAktuell', []))
        );

    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }


    private log(message: string) {
        console.log('LogInfo: ' + message);
    }

}

