﻿export class MailInfo {
    vorname: string;
    nachname: string;
    adresse: string;
    plz: string;
    wohnort: string;
    email: string;
    phone: string;
    message: string;
}



