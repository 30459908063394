import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Injectable } from '@angular/core';
import { MailService } from '../../Service/mail.service';
import { MailInfo } from '../../Model/MailInfo';
import { Validators, FormBuilder, FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { emailValidator, EmailValidator} from '../../Model/app-validators';


@Component({
  selector: 'app-kontaktformular',
  templateUrl: './kontaktformular.component.html',
  styleUrls: ['./kontaktformular.component.css'],
  providers: [MailService]

})
export class KontaktformularComponent implements OnInit {


    submitted: boolean = false;
    model: MailInfo = new MailInfo();
    registerForm: FormGroup;


    ngOnInit() {
    //    this.registerForm = this.formBuilder.group({
    //        email: ['', [Validators.required, Validators.email]]
    //    }, {
    //            validator: MustMatch('password', 'confirmPassword')
    //        });
    }
 

    constructor(private mailService: MailService) {
    }



    onSubmit() {
        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.model))


        console.log(this.model);
        this.mailService.sendEmail(this.model);
        this.submitted = true;

    }

}
