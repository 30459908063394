import { Component, OnInit } from '@angular/core';
import { Team } from '../../Model/team';
import { TeamService } from '../../Service/team.service';


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

    constructor(private teamservice: TeamService) { }

    teamList: Team[] = [];

    ngOnInit() {
        this.getTeam();
    }


    getTeam(): void {
        console.log("team");
        this.teamservice.getTeam().subscribe(
            data => {
                this.teamList = data;
            },
            err => {
                console.log(' Error in reiseList elements');
            },
            () => {
                console.log('Teamlist loaded');
            }
        );

    }

}
