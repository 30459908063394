import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    company: string = 'sprintsuisse.ch AG';
    address: string = 'Baarermattstrasse 18';
    email: string = 'info@sprintsuisse.ch';
    plz: string = '6340';
    ort: string = 'Baar';
    tel: string = '+41 41 761 68 68';

    spacer: string = ' ';

}
