import { Injectable } from '@angular/core';
import { Reisen } from '../Model/reisen';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MailInfo } from '../Model/MailInfo';

@Injectable({
  providedIn: 'root'
})
export class MailService {

    constructor(private http: HttpClient) {

    }

    endpoint = 'https://sprintsuisse.ch/assets/sendEmail.php';

    hero: MailInfo;

    //sendEmail(_vorname: string, _nachname: string, _adresse: string, _plz: string, _wohnort: string, _email: string, _phone: string, _message: string, _sendermail: string) {
    //    console.log(_vorname);
    //    console.log(_nachname);
    //    console.log(_adresse);
    //    console.log(_plz);
    //    console.log(_wohnort);
    //    console.log(_email);
    //    console.log(_phone);
    //    console.log(_message);

    //    let postVars = {
    //        vorname: _vorname,
    //        nachname: _nachname,
    //        adresse: _adresse,
    //        plz: _plz,
    //        wohnort: _wohnort,
    //        email: _email,
    //        phone: _phone,
    //        message: _message,
    //        sendermail: _sendermail
    //    };

    //    const httpOptions = {
    //        headers: new HttpHeaders({
    //            'Content-Type': 'application/json',
    //            'Access-Control-Allow-Origin': '*'
    //        })
    //    };

    //    this.http.post(this.endpoint, postVars, httpOptions)
    //        .subscribe(
    //        response => console.log(response)
    //        )
    //}


    handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {
        console.log("there was a error");
        return (error: HttpErrorResponse): Observable<T> => {
            // Todo -> Send the error to remote logging infrastructure
            console.log("Post failled");
            console.error(error); // log to console instead

            //const message = (error.error instanceof ErrorEvent) ?
            //    error.error.message :
            //    `{error code: ${error.status}, body: "${error.message}"}`;

            // -> Return a safe result.
            return of(result);
        };
    }




    //sendEmail(mailInfo: IMailInfo): Observable<IMailInfo> | any {
    //    console.log('Intro SendMail');

    //    const httpOptions = {
    //        headers: new HttpHeaders({
    //            'Content-Type': 'application/json',
    //            'Access-Control-Allow-Origin': '*'
    //        })
    //    };


    //    console.log(mailInfo);
    //    return this.http.post(this.endpoint, mailInfo,httpOptions).pipe(map(response => {
    //                console.log('Sending email was successfull', response);
    //                return response;
    //    })),
    //        catchError(this.handleError('sendEmail'))
    //    }

    

    //sendEmail(hero: MailInfo): Observable<MailInfo> {
    //    const httpOptions = {
    //        headers: new HttpHeaders({
    //            'Content-Type': 'application/json',
    //            'Access-Control-Allow-Origin': '*'
    //        })
    //    };

    //    return this.http.post<MailInfo>(this.endpoint, hero, httpOptions)
    //        .pipe(
    //        catchError(this.handleError('sendEmail'))
    //        );
    //}


    //sendEmail(hero: MailInfo): Observable<MailInfo> {
    //    const httpOptions = {
    //        headers: new HttpHeaders({
    //            'Content-Type': 'application/json',
    //            'Access-Control-Allow-Origin': '*'
    //        })
    //    };
    //    return this.http.post<MailInfo>(this.endpoint, hero, httpOptions)
    //        .pipe(
    //        catchError(this.handleError('updateHero'))
    //        );
    //}

    //sendEmail(data: MailInfo) {
    //    console.log(data);
    //    const httpOptions = {
    //        headers: new HttpHeaders({
    //            'Content-Type': 'application/json; charset=utf-8',
    //            'Access-Control-Allow-Origin': '*'
    //        })
    //    };
        
    //    this.http.post(this.endpoint, data, httpOptions)
    //        .subscribe(
    //        response => console.log(response)
    //        )
    //}


    sendEmail(mailInfo: MailInfo) {
        //console.log(mailInfo);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8'})
        };

        let body = new FormData();
        body.append('vorname', mailInfo.vorname);
        body.append('nachname', mailInfo.nachname);
        body.append('adresse', mailInfo.adresse);
        body.append('plz', mailInfo.plz);
        body.append('wohnort', mailInfo.wohnort);
        body.append('email', mailInfo.email);
        body.append('phone', mailInfo.phone);
        body.append('message', mailInfo.message);

        let data = { 'vorname': mailInfo.vorname, 'nachname': mailInfo.nachname, 'adresse': mailInfo.adresse, 'plz': mailInfo.plz, 'wohnort': mailInfo.wohnort, 'email': mailInfo.email, 'phone': mailInfo.phone, 'message': mailInfo.message };

        console.log(data);


        //this.http.post(this.endpoint, data, httpOptions)
        //    .subscribe(
        //    response => console.log(response), err => console.log(err)
        //    )

        this.http.post(this.endpoint, data, httpOptions)
            .subscribe(
            response => console.log(response),
            err => console.log(err)
            )

    }



}
