import { Injectable } from '@angular/core';
import { Bericht } from '../Model/bericht';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReiseberichtService {

    filename: string;

    constructor(private _http: HttpClient) {
        this.filename = '../Data/Bericht.json';
    }




    getReisenberichte(): Observable<Bericht[]> {
        return this._http.get<Bericht[]>(this.filename).pipe(
            catchError(this.handleError('getBericht', []))
        );

    }



    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }



    private log(message: string) {
        console.log('LogInfo: ' + message);
    }

}