import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fahrzeuge',
  templateUrl: './fahrzeuge.component.html',
  styleUrls: ['./fahrzeuge.component.css']
})
export class FahrzeugeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
