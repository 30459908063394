import { Component, OnInit } from '@angular/core';
import { Aktuell } from '../../Model/aktuell';
import { AktuellService } from '../../Service/aktuell.service';

@Component({
  selector: 'app-aktuell',
  templateUrl: './aktuell.component.html',
  styleUrls: ['./aktuell.component.css']
})
export class AktuellComponent implements OnInit {

    constructor(private aktuellservice: AktuellService) { }

    aktuellList: Aktuell[] = [];

    ngOnInit() {
        this.getAktuell();
    }


    getAktuell(): void {
        console.log("Aktuell");
        this.aktuellservice.getAktuell().subscribe(
            data => {
                this.aktuellList = data;
            },
            err => {
                console.log(' Error in List elements');
            },
            () => {
                console.log('Liste  loaded');
            }
        );

    }

}
