import { Pipe, PipeTransform } from '@angular/core';
import { Reisen } from '../Model/reisen';

@Pipe({
  name: 'reiseliste'
})
export class ReiselistePipe implements PipeTransform {

    transform(value: Reisen[], DatumFilter: Date): Reisen[] {
        if (value && value.length) {
            const val1 = value.filter(x => (new Date(x.Reisedatum) > DatumFilter));
            return val1;
        }
        else {
            return value;
        }
    }
}

